import Props from '@common/Props.interface'
import { Theme } from '@components/shared'
import classNames from '@utils/classNames'
import isNullOrUndefined from '@utils/isNullOrUndefined'
import isString from '@utils/isString'
import { ReactNode } from 'react'

enum Variant {
  TEXT_LEFT = 'TEXT_LEFT',
  TEXT_CENTER = 'TEXT_CENTER',
}

enum Width {
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
}

export interface HeadingProps extends Props {
  heading: string | ReactNode
  subheading?: string | ReactNode | null
  supheading?: string | null
  variant?: Variant
  width?: Width
}

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.TEXT_CENTER]: 'items-center text-center',
  [Variant.TEXT_LEFT]: 'items-start text-left',
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'text-orange-500',
  [Theme.GREEN]: 'text-green-500',
}

const WIDTH_MAPS: Record<Width, string> = {
  [Width.DEFAULT]: 'max-w-4xl',
  [Width.FULL]: '',
}

const Heading = ({
  heading,
  theme,
  width = Width.DEFAULT,
  supheading = null,
  subheading = null,
  variant = Variant.TEXT_CENTER,
}: HeadingProps) => {
  const variantClassName = !isNullOrUndefined(variant) ? VARIANT_MAPS[variant!] : null
  const themeClassName = !isNullOrUndefined(theme) ? THEME_MAPS[theme!] : null
  const widthClassName = !isNullOrUndefined(width) ? WIDTH_MAPS[width!] : null

  let TheSubheading = null
  if (subheading !== null) {
    TheSubheading = isString(subheading) ? (
      <div dangerouslySetInnerHTML={{ __html: subheading as string }} />
    ) : (
      subheading
    )
  }
  return (
    <div
      className={classNames('mx-auto flex w-full flex-col gap-5', widthClassName, variantClassName)}
    >
      <div className="flex flex-col gap-2.5">
        {supheading && (
          <div
            className={classNames('text-2xl font-bold', themeClassName)}
            dangerouslySetInnerHTML={{ __html: supheading }}
          />
        )}
        <h1 className="heading-lg" dangerouslySetInnerHTML={{ __html: heading as string }} />
      </div>
      {TheSubheading}
    </div>
  )
}

Heading.variant = Variant

export default Heading
